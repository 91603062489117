import { useContext } from 'react';
import { GearIcon } from 'assets/icons';
import { useSession } from 'contexts';
import { useQuery } from '@apollo/client';
import { GetOrganizationSettingsDocument, GetUserInfoDocument} from 'graphql/generated';
import NotificationsSettings from './Partials/NotificationsSettings';
import Toast, { useToast } from 'components/Toast/Toast';
import UserDocuments from './Partials/UserDocuments';
import OrganizationDocuments from './Partials/OrganizationDocuments';
import Tabs from 'components/Tabs/Tabs';
import IntergationsSettings from './Partials/IntergationsSettings';
import OrganizationSettings from './Partials/OrganizationSettings';
import UsersSettings from './Partials/UsersSettings';
import BillingSettings from './Partials/BillingSettings';
import { AbilityContext } from 'contexts/AbilityContext';
import useParamState from 'hooks/useParamState';
import isMobileApp from 'utils/isMobileApp';
import { doesContatinSubjects } from 'utils/PermissionHandler';
import ProfileSettings from './Partials/ProfileSettings';

const Settings = function () {
  const ability = useContext(AbilityContext);
  const { organizationId } = useSession();
  const { toastProps } = useToast();
  const [activeTab, setActiveTab] = useParamState('settingsTab', 'Profile');
  
  
  const tabs = ['Profile', 'Notifications', 
  ...(doesContatinSubjects({ action:'PART91', subjects:['Admin'], ability}) ? ['Organization'] : []),
  ...(doesContatinSubjects({ action:'PART91', subjects:['Admin'], ability}) && !isMobileApp() ? ['Billing'] : []),
  , 'Users',  'Integrations', 'Organization Documents', 'Personal Documents' ];
  const { data: { me } = { } } = useQuery(GetUserInfoDocument, { fetchPolicy: 'network-only' });
  const { data: { organization } = {}, refetch } = useQuery(GetOrganizationSettingsDocument, { variables: { id: organizationId }  }); 

  return (
    <>
      {/* HEADER */}
      <div className="flex flex-col bg-white border border-slate-300 rounded mb-2 shadow-blue">
        <div className="flex items-center p-6">
          <GearIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
          <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Settings</h1>
        </div>
        <div className="flex items-start bg-slate-50 px-6 rounded-b border-t">
          <Tabs items={tabs} activeItem={activeTab} setActiveItem={setActiveTab} />
        </div>
      </div>
      {/* PROFILE */}
      { activeTab === 'Profile' && <ProfileSettings />}
      {/* NOTIFICATIONS */}
      { activeTab === 'Notifications' && <NotificationsSettings settings={me?.selectedOrganizationProfile?.notificationSettings} id={me?.selectedOrganizationProfile?.id}/> }
      {/* ORGANIZATION */}
      { activeTab === 'Organization' && <OrganizationSettings organization={organization} /> }
      {/* BILLING */}
      { activeTab === 'Billing' && <BillingSettings organization={organization} organizationRefetch={refetch} /> }
      {/* USERS */}
      { activeTab === 'Users' && <UsersSettings /> }
      {/* INTEGRATIONS DOCUMENTS */}
      { activeTab === 'Integrations' && <IntergationsSettings organization={organization} /> }
      {/* ORGANIZATION DOCUMENTS */}
      { activeTab === 'Organization Documents' && <OrganizationDocuments /> }
      {/* USER DOCUMENTS */}
      { activeTab === 'Personal Documents' && <UserDocuments/> }
    </>
  );
};

export default Settings;
