import Button from "components/Button/Button";
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { ChangePasswordDocument, GetTimezoneDocument, GetUserInfoDocument, GetUserOrganizationProfilesDocument, UpdateUserProfileDocument } from 'graphql/generated';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import isMobileApp from 'utils/isMobileApp';
import { useSession } from "contexts";
import { useState, useEffect } from "react";
const Timezones = () => {
  return (
    <>
      <option value="-12">(GMT-12:00) International Date Line West</option>
      <option value="-11">(GMT-11:00) American Samoa</option>
      <option value="-10">(GMT-10:00) Hawaii</option>
      <option value="-09">(GMT-09:00) Alaska</option>
      <option value="-08">(GMT-08:00) Pacific Time (US & Canada)</option>
      <option value="-07">(GMT-07:00) Mountain Time (US & Canada)</option>
      <option value="-06">(GMT-06:00) Central Time (US & Canada)</option>
      <option value="-05">(GMT-05:00) Eastern Time (US & Canada)</option>
      <option value="-04">(GMT-04:00) Atlantic Time (Canada)</option>
      <option value="-03:30">(GMT-03:30) Newfoundland</option>
      <option value="-03">(GMT-03:00) Brasilia</option>
      <option value="-02">(GMT-02:00) Mid-Atlantic</option>
      <option value="-01">(GMT-01:00) Azores</option>
      <option value="00">(GMT±00:00) Greenwich Mean Time (GMT)</option>
      <option value="01">(GMT+01:00) Central European Time (CET)</option>
      <option value="02">(GMT+02:00) Eastern European Time (EET)</option>
      <option value="03">(GMT+03:00) Moscow</option>
      <option value="03:30">(GMT+03:30) Iran</option>
      <option value="04">(GMT+04:00) Azerbaijan</option>
      <option value="04:30">(GMT+04:30) Afghanistan</option>
      <option value="05">(GMT+05:00) Pakistan</option>
      <option value="05:30">(GMT+05:30) India</option>
      <option value="05:45">(GMT+05:45) Nepal</option>
      <option value="06">(GMT+06:00) Bangladesh</option>
      <option value="06:30">(GMT+06:30) Cocos Islands</option>
      <option value="07">(GMT+07:00) Indochina</option>
      <option value="08">(GMT+08:00) China</option>
      <option value="08:45">(GMT+08:45) Western Australia</option>
      <option value="09">(GMT+09:00) Japan</option>
      <option value="09:30">(GMT+09:30) Central Australia</option>
      <option value="10">(GMT+10:00) Eastern Australia</option>
      <option value="10:30">(GMT+10:30) Lord Howe Island</option>
      <option value="11">(GMT+11:00) Solomon Islands</option>
      <option value="11:30">(GMT+11:30) Norfolk Island</option>
      <option value="12">(GMT+12:00) Fiji</option>
      <option value="12:45">(GMT+12:45) Chatham Islands</option>
      <option value="13">(GMT+13:00) Tonga</option>
      <option value="14">(GMT+14:00) Line Islands</option>
    </>
  )
};

export default function ProfileSettings() {
    const { user, organizationId } = useSession();
    const { toastProps, showToast } = useToast();
    const { data: { me } = { } } = useQuery(GetUserInfoDocument, { fetchPolicy: 'network-only' });
    const [updateProfile] = useMutation(UpdateUserProfileDocument, { refetchQueries: [{ query: GetUserOrganizationProfilesDocument, variables:{ where: { organizationId: { equals: organizationId } } } }, GetUserInfoDocument, GetTimezoneDocument ] });
    const [changePassword] = useMutation(ChangePasswordDocument);
    const [formPrefill, setFormPrefill] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      timezone: '',
      airmenName: '',
      airmenNumber: '',
      licenseType: '',
    });
    useEffect(() => {
      if (me)
        setFormPrefill((prefill) => ({
          ...prefill,
          firstName: me?.firstName,
          lastName: me?.lastName,
          email: me?.email,
          phone: me?.selectedOrganizationProfile?.phone,
          timezone: me?.timezone,
          airmenName: me?.airmenLicense?.airmenName,
          airmenNumber: me?.airmenLicense?.airmenNumber,
          licenseType: me?.airmenLicense?.licenseType,
        }));
    }, [me]);

    return (
      <>
        <Toast {...toastProps} position='bottom' />
        <div className="bg-white border border-slate-300 rounded shadow-blue">
          <div className="flex flex-col">
            <Formik
              enableReinitialize
              initialValues={formPrefill}
              onSubmit={async (values: typeof formPrefill, { setFieldValue }: FormikHelpers<typeof formPrefill>) => {
                try {
                  if (values.newPassword && values.newPassword === values.confirmPassword) {
                    await changePassword({
                      variables: {
                        oldPassword: values.password,
                        newPassword: values.newPassword,
                      },
                    });
                  } else if (values.newPassword && values.newPassword !== values.confirmPassword) {
                    showToast({title: "Both passwords must match.", type: ToastLength.Normal});
                    return;
                  }
                  const result = await updateProfile({
                    variables: {
                      input: {
                        id: user.userOrganizationProfileId,
                        phone: values.phone,
                        user:{ update: { data:{ 
                          firstName: values.firstName,
                          lastName: values.lastName,
                          email: values.email.toLowerCase(),
                          timezone: values.timezone,
                          airmenLicense: {
                            airmenName: values.airmenName,
                            airmenNumber: values.airmenNumber,
                            licenseType: values.licenseType,
                          }
                        } } }
                      }
                    },
                  });
                } catch (error: any) {
                  if( error instanceof ApolloError ){
                    showToast({ title: error.message, type: ToastLength.Long});
                    return;
                  }
                  console.log(error);
                  
                }
                setFieldValue('password', '');
                showToast({ title: 'Profile Updated', type: ToastLength.Normal});
              }}>
              {({ values, setFieldValue }) => (
                <Form className="flex flex-col w-full lg:w-2/3 gap-2 p-10 rounded-l">
                  <span className="font-bold text-xs text-brand uppercase mb-6 border-b border-slate-200 py-0.5 -mx-4 pl-4">
                    User Information
                  </span>
                  <div className="flex items-start">
                    <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Name</span>
                    <div className="flex flex-col w-2/3">
                      <FullFormikInput name="firstName" placeholder="First Name" />
                      <FullFormikInput name="lastName" placeholder="Last Name" />
                    </div>
                  </div>
                  <div className="flex items-start">
                    <span className="w-1/3 text-sm font-medium text-slate-500">Email</span>
                    <div className="flex flex-col w-2/3">
                      <FullFormikInput name="email" />
                    </div>
                  </div>
                  <div className="flex">
                    <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Phone</span>
                    <div className="flex items-center w-2/3">
                      <div className="rounded-l p-2 border border-r-0 w-9 font-semibold text-sm">+1</div>
                      <Field name="phone" className="rounded-r border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full" />
                    </div>
                  </div>
                  <div className="flex items-center pt-3">
                    <span className="w-1/3 text-sm font-medium text-slate-500">Timezone</span>
                    <select value={values.timezone} onChange={(e) => setFieldValue('timezone', e.target.value)} className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-2/3 text-ellipsis pr-5">
                      <Timezones />
                    </select>
                  </div> 
                  <span className="font-bold text-xs text-brand uppercase my-6 border-b border-slate-200 py-0.5 -mx-4 pl-4">
                    Airmen License Information
                  </span>
                  <div className="flex items-center">
                    <span className="w-1/3 text-sm font-medium text-slate-500">Airmen Name</span>
                    <Field name="airmenName" className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-2/3" />
                  </div>
                  <div className="flex">
                    <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Airmen Number</span>
                    <Field name="airmenNumber" className="rounded border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-2/3" />
                  </div>
                  <div className="flex items-center">
                    <span className="w-1/3 text-sm font-medium text-slate-500">License Type</span>
                    <FullFormikSelect name="licenseType" className=" w-2/3">
                      <option></option>
                      <option>Pilot</option>
                      <option>A&P</option>
                      <option>IA</option>
                    </FullFormikSelect>
                  </div>
                  <span className="font-bold text-xs text-brand uppercase my-6 border-b border-slate-200 py-0.5 -mx-4 pl-4">
                    Change Password
                  </span>
                  <div className="flex mb-4">
                    <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Password</span>
                    <div className="flex flex-col w-2/3">
                      <FullFormikInput name="newPassword" type="password" autoComplete="new-password" placeholder="New Password" />
                      <FullFormikInput name="confirmPassword" type="password" autoComplete="new-password" placeholder="Confirm New Password" />
                    </div>
                  </div>
                  <div className="flex mb-4">
                    <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Current Password*</span>
                    <div className="flex flex-col w-2/3">
                      <FullFormikInput name="password" type="password" autoComplete="false" placeholder="Current Password" />
                    </div>
                  </div>
                  <Button text="Save" color="navy" size="sm" type="submit" />
                  { isMobileApp() && <div className='flex flex-col text-center gap-2 mt-20 justify-center'>
                    <Button text="Request account deletion" color="red" size="xs" onClick={()=>  window.location.href ='https://forms.gle/KCfAPtoSsa58iLTy7'} />
                    <div className='text-xs'>*Include email and ask for account deletion in contact form*</div>
                  </div> }
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    )
}